a.external {
  background: url('img/external.png') center right no-repeat;
  padding-right: 13px;
  text-decoration: none;
}

.ag-main {
  height: 700px;
  width: 100%;
}

.ag-theme-material .ag-row .ag-cell-data-changed {
  background-color: #fff5b8 !important;
}
